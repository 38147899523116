import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import CountUp from "react-countup"
import Loadable from "@loadable/component"
import Layout from "../components/layout"
import "bootstrap/dist/css/bootstrap.min.css"
// import Image from "../components/image"
import SEO from "../components/seo"
import "owl.carousel/dist/assets/owl.carousel.css"
// import ContactForm from "../components/contact"
import { LazyLoadImage } from "react-lazy-load-image-component"
import TechnologyStack from "./TechnologyStack"
import career from "../../career.json"

const testimonials = [
  {
    quote:
      "I would like to personally thank you for your outstanding product. It's the perfect solution for our business. They are pro in web application development services. Highly recommended.",
    author: "Saul Goodman",
  },
  {
    quote:
      "D2i Technology is awesome! I like D2i Technology more and more each day because it makes my life a lot easier. No one is ready to touch our code because of complexity. They took the challenge and did our custom development work smoothly.",
    author: "Gwendolen G.",
  },
  {
    quote:
      "You won't regret it. D2i Technology did exactly what you said it does. I am completely blown away.",
    author: "Cally H.",
  },
  {
    quote:
      "This is simply unbelievable! I would also like to say thank you to all your staff. We hired them to do accessibility testing of our application they not only tested, they helped to fix our accessibility & functional bugs and improve our HTML structure so that custom code for accessibility is not required. I liked the approach. Best accessibility testing company.",
    author: "Matt Brandon",
  },
  {
    quote:
      "Thank you for nice work in doing SEO our application, and most of all hassle free! I don't know what else to say. We have no regrets!",
    author: "John Larson",
  },
  {
    quote:
      "I call them best software development and testing company because they took complete ownership of my project. They just don't follow the requirement document shared, whole team will use the brain to think from users perspective. Inputs shared by their developers & QA helped me to improve UX.",
    author: "Mark",
  },
]

const IndexPage = () => {
  const activeJobs = career.career.filter(
    job => job.status.toLowerCase() === "active"
  )

  const [currentIndex, setCurrentIndex] = useState(0)
  const carouselRef = useRef(null)

  const handleNext = () => {
    setCurrentIndex(prev => (prev === testimonials.length - 1 ? 0 : prev + 1))
  }

  const handlePrevious = () => {
    setCurrentIndex(prev => (prev === 0 ? testimonials.length - 1 : prev - 1))
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case "ArrowLeft":
        handlePrevious()
        break
      case "ArrowRight":
        handleNext()
        break
      case "Home":
        setCurrentIndex(0)
        break
      case "End":
        setCurrentIndex(testimonials.length - 1)
        break
      default:
        break
    }
  }

  return (
    <Layout>
      <SEO
        title="Software Development, DevOps, Accessibility Testing Company in USA"
        description="D2i Technology is a top software company in the USA, offering expert services in software development, DevOps, and accessibility testing. Look no further. Our team of experts can help you develop, deploy, and test software with ease."
      />
      
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container position-relative"
          data-aos-todo="fade-up"
          data-aos-todo-delay="100"
        >
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 text-center">
              <h1 className="lead">Turning the digital dreams into reality</h1>
              <div className="section-title">
              <p>
                We are a team of talented software developers turning the
                digital dreams of our clients into reality.
              </p>
              </div>
            </div>
          </div>

          <div className="row icon-boxes" role="list">
            <div
              className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
              role="listitem"
            >
              <div className="icon-box">
                <div className="icon">
                  <CountUp end={65} />
                </div>
                <div className="title">Happy Clients</div>
              </div>
            </div>

            <div
              className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
              role="listitem"
            >
              <div className="icon-box">
                <div className="icon">
                  <CountUp end={85} />
                </div>
                <div className="title">Projects</div>
              </div>
            </div>

            <div
              className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="400"
              role="listitem"
            >
              <div className="icon-box">
                <div className="icon">
                  <CountUp end={15} />
                </div>
                <div className="title">Years of experience</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="about">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
            <p>
              At D2i Technology, we turn the digital dreams of our clients into
              reality.{" "}
            </p>
          </div>
          <div className="embed-responsive embed-responsive-16by9 mb-5">
            <iframe
              className="embed-responsive-item"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/0B1q4cI93io"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Welcome to D2i Technology - Best Custom Software Development, Digital Marketing & Testing Services"
              loading="lazy"
            ></iframe>
          </div>

          <div className="row content">
            <div className="col-12 pb-4">
              <p className="lead text-center">
                We work closely with our users throughout the development
                process to ensure that we are aligned with the end goal. We are
                committed to producing exceptional software for each of our
                clients.
              </p>
            </div>
          </div>
          <div className="row content">
            <div className="col-lg-6">
              <h3>Rigorous Testing</h3>
              <p>
                Once we have finished the initial development of our client's
                product, we begin performing meticulous bug checks. These tests
                are included in the price of the development package.{" "}
              </p>
              <p>After testing, we deliver the software to our clients. </p>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h3>Quality Guaranteed</h3>
              <p>
                The world of technology can be fast-paced and scary. That's why
                our goal is to provide a high-quality product that aligns with
                your company's needs. No matter the budget, we pride ourselves
                on providing professional customer service. We guarantee you
                will be satisfied with our work.
              </p>
            </div>
          </div>
          <div className="row content">
            <div className="col-12 pt-4 text-center">
              <a href="/contact" className="btn-learn-more">
                Learn More <span className="visually-hidden">Our Testing and Quality Assurance</span>
              </a>
              <a href="/schedule-a-call" className="btn-schedule-call">
                Schedule a Call
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="portfolio" className="portfolio">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Our Products</h2>
          </div>

          <div className="row icon-boxes mt-0">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/marathi_news.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.androidlive.news.marathi"
                    target="_blank"
                  >
                    Marathi News
                  </a>
                </h3>
                <p className="description">
                  Marathi News is one of the most widely read Marathi language
                  newspaper all top News
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/Hindi_Local_News.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.androidlive.news.up"
                    target="_blank"
                  >
                    Hindi News (Local News)
                  </a>
                </h3>
                <p className="description">
                  Hindi News (Local News) is an Android application that allows
                  users to read Hindi Samachar, Hindi Newspaper, Breaking news,
                  etc.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/Bihar_News.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.androidlive.news.br"
                    target="_blank"
                  >
                    Bihar News
                  </a>
                </h3>
                <p className="description">
                  Bihar Hindi News is an Android application to get the latest
                  and breaking news of Bihar in Hindi from your favourite
                  newspapers & TV Channels websites.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/hellokaun.webp"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.d2i_technology.hellokaun"
                    target="_blank"
                  >
                    Hello Kaun
                  </a>
                </h3>
                <p className="description">
                  Hello Kaun is a made in India stress buster app built on{" "}
                  <a href="https://my.atsign.com/go/overall1" target="_blank">
                    @ protocol
                  </a>{" "}
                  for fun that will make you smile by sending messages in
                  different accent, pitch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="clients" className="clients">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Our Clients</h2>
          </div>

          <div className="row icon-boxes mt-0">
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/atsign-logo.svg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a href="https://atsign.com/" target="_blank">
                    Atsign
                  </a>
                </h3>
                <p className="description">
                  Atsign is flipping the Internet by giving consumers control of
                  their private information through the open-source @platform.
                  This framework enables developers to create applications for
                  People, Entities, and Things, featuring secure exchange of
                  information between known parties.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/emper.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a href="https://emper.com/" target="_blank">
                    Emper
                  </a>
                </h3>
                <p className="description">
                  Emper is a cloud-based self-service interior book formatting
                  and design program, primarily for indie publishers. Specialize
                  is converting a raw manuscript into a professionally formatted
                  and designed book for publication.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/studio22.jpg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a href="https://www.studio22.com/" target="_blank">
                    Studio 22 Design
                  </a>
                </h3>
                <p className="description">
                  Creating digital experiences that ignite growth.
                  <br /> Web | Branding | UX/UI.
                </p>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 d-flex align-items-stretch mb-4 aos-init aos-animate"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <div className="icon-box px-3">
                <div className="mb-4">
                  <LazyLoadImage
                    src="assets/img/knowaround.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <h3 className="title">
                  <a href="http://knowaround.com/" target="_blank">
                    Knowaround
                  </a>
                </h3>
                <p className="description">
                  KnowAround.com allow you to come to one place for access to
                  comprehensive information focused on the assessment of a
                  location in order to help you make informed decisions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services section-bg">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <p>
              We offer a wide range of services covering every aspect of the
              software development life cycle.
            </p>
          </div>

          <div className="row justify-content-center">
            <div
              id="manual-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="100"
            >
              <Link to="/manual-testing/" className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h3>Functional Testing</h3>
                  <p>
                    We play the role of an end-user using most of the
                    application's features to ensure correct behavior. Our
                    manual testing services not only is limited to find
                    functional defects, we go an extra mile to check usability,
                    performance and accessibility defects.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="automation-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <Link to="/automation-testing/" className="d-flex">
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <i className="bx bx-file"></i>
                  </div>
                  <h3>Automation Testing</h3>
                  <p>
                    We offer automation testing critical for continuous delivery
                    and continuous testing of your application.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="performance-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/performance-testing/" className="d-flex">
                <div className="icon-box iconbox-pink">
                  <div className="icon">
                    <i className="bx bx-tachometer"></i>
                  </div>
                  <h3>Performance Testing</h3>
                  <p>
                    We provide performance testing to determine how your
                    application performs in terms of responsiveness and
                    stability under the workload.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="accessibility-testing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="100"
            >
              <Link to="/accessibility-testing/" className="d-flex">
                <div className="icon-box iconbox-yellow">
                  <div className="icon">
                    <i className="bx bx-layer"></i>
                  </div>
                  <h3>Accessibility Testing</h3>
                  <p>
                    We ensure your application is usable by people with
                    disabilities like hearing, color blindness, old age, and
                    other disadvantaged groups. We provide VPAT report as well.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="web-development"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="200"
            >
              <Link to="/website-development/" className="d-flex">
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <i className="bx bx-slideshow"></i>
                  </div>
                  <h3>Web Development</h3>
                  <p>
                    We develop responsive and optimized web applications as per
                    your needs following recommended web practices.
                  </p>
                </div>
              </Link>
            </div>

            <div
              id="mobile-development"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/mobile-development/" className="d-flex">
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <i className="bx bx-mobile-alt"></i>
                  </div>
                  <h3>Mobile Development</h3>
                  <p>
                    At D2i Technology, we provide mobile application development
                    services.
                  </p>
                </div>
              </Link>
            </div>
            <div
              id="accessibility-remediation"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/accessibility-remediation/" className="d-flex">
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <i className="bx bx-accessibility"></i>
                  </div>
                  <h3>Accessibility Remediation</h3>
                  <p>
                    Our accessibility remediation service provides an accurate
                    and cost-effective way to achieve Section 508, ADA, or WCAG
                    2.0 compliance.
                  </p>
                </div>
              </Link>
            </div>
            <div
              id="devops"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/devops/" className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <i className="bx bx-cloud"></i>
                  </div>
                  <h3>DevOps</h3>
                  <p>
                    Shorten your software development life cycle and provide
                    continuous delivery with high software quality with our
                    DevOps service.
                  </p>
                </div>
              </Link>
            </div>
            <div
              id="digitalmarketing"
              className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
              data-aos-todo="zoom-in"
              data-aos-todo-delay="300"
            >
              <Link to="/digital-marketing/" className="d-flex">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <i className="bx bx-camera-movie"></i>
                  </div>
                  <h3>Digital Marketing</h3>
                  <p>
                    As Digital Marketing Company we provide best services which
                    offers comprehensive Emails, Social media posts, Local
                    listing, Blogging and Online marketing services that will
                    help you to increase online presence resulting your business
                    to grow.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <TechnologyStack />
      <section id="testimonials" className="testimonials">
        <div className="container" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>
          <div className="row justify-content-center">
            <div className="carousel slide" ref={carouselRef}>
              <div
                role="region"
                aria-roledescription="carousel"
                aria-label="Customer Testimonials"
                className="position-relative"
              >
                {/* Screen reader announcement */}
                <div
                  className="sr-only"
                  aria-live="polite"
                  aria-atomic="true"
                >
                  Viewing testimonial {currentIndex + 1} of{" "}
                  {testimonials.length}
                </div>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    onClick={handlePrevious}
                    onKeyDown={handleKeyDown}
                    className="btn btn-primary position-absolute"
                    aria-label="Previous testimonial"
                  >
                    <span className="sr-only">Previous</span>
                    <span aria-hidden="true">&laquo;</span>
                  </button>

                  <button
                    onClick={handleNext}
                    onKeyDown={handleKeyDown}
                    className="btn btn-primary position-absolute"
                    aria-label="Next testimonial"
                  >
                    <span className="sr-only">Next</span>
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </div>

                {/* Testimonial Cards */}
                <div className="carousel-inner overflow-hidden" aria-live="polite">
                  {testimonials.map((testimonial, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        index === currentIndex ? "active" : ""
                      }`}
                      role="group"
                      aria-roledescription="slide"
                      aria-label={`Testimonial ${index + 1} of ${
                        testimonials.length
                      }`}
                      aria-hidden={index !== currentIndex}
                      style={{
                        transition: "opacity 0.5s ease-in-out",
                        opacity: index === currentIndex ? 1 : 0,
                      //   position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <div className="card border-0 shadow-sm testimonial-item">
                        <div className="card-body p-4">
                          <blockquote className="blockquote mb-0">
                            <p className="mb-4">
                              <span className="quote-icon" aria-hidden="true">
                                <i className="bx bxs-quote-alt-left quote-icon-left mr-3"></i>
                              </span>
                              {testimonial.quote}
                              <span className="quote-icon" aria-hidden="true">
                                <i className="bx bxs-quote-alt-right quote-icon-right ml-3"></i>
                              </span>  
                            </p>
                            <footer className="blockquote-footer">
                              <cite title="Author"><strong>{testimonial.author}</strong></cite>
                            </footer>
                          </blockquote>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Indicators */}
                <div className="d-flex justify-content-center mt-4">
                  {testimonials.map((_, index) => (
                    <button
                      key={index}
                      className={`btn btn-sm mx-2 rounded-circle ${
                        index === currentIndex
                          ? "btn-primary"
                          : "btn-secondary"
                      }`}
                      onClick={() => setCurrentIndex(index)}
                      aria-label={`Go to testimonial ${index + 1}`}
                      aria-pressed={index === currentIndex}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="careers" className="careers team section-bg">
        <div className="container aos-init aos-animate" data-aos-todo="fade-up">
          <div className="section-title">
            <h2>Careers</h2>
            <p>We are hiring for Software Engineer for mentioned profiles.</p>
          </div>
          <div className="row justify-content-center">
            {activeJobs.map(job => (
              <div
                key={job.id}
                className="col-lg-5 col-md-6 d-flex align-items-stretch"
                data-aos="fade-up"
                data-aos-delay={100 * job.id}
              >
                <Link to={`/${job.url}`} className="member">
                  <div className="member-info">
                    <h3>{job.title}</h3>
                    <p className="mb-0">{job.summary}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="row content">
            <div className="col-12 pt-4 text-center">
              <a
                href="https://forms.gle/mCYwx55YhT6vA1cJ7"
                className="btn btn-primary"
                target="_blank"
              >
                APPLY NOW
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
